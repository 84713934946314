export default {
  app: {
    orgCode: 'លេខសំរាប់​ក្រុម/ម៉ាក',
    orgCodePlaceholder: 'សូមបញ្ចូលលេខសំរាប់ក្រុម/ម៉ា',
    username: 'គណនី',
    usernamePlaceholder: 'សូមបញ្ចូលគណនីមួយ។',
    password: 'ពាក្យសម្ងាត់',
    passwordPlaceholder: 'សូមបញ្ចូលពាក្យសម្ងាត់',
    captcha: 'កូដ​ផ្ទៀង​ផ្ទាត់',
    captchaPlaceholder: 'សូមបញ្ចូលលេខកូដផ្ទៀងផ្ទាត់',
    captchaExpired: 'សូមធ្វើឱ្យទ្រង់ទ្រាយកូដបញ្ជាក់រួចស្រេច',
    login: 'ចូល',
    remember: 'ចងចាំព័ត៌មានចូល',
    switchPC: 'ភាសាចំណុចប្រទាក់',
    setupPassword: 'កំណត់ពាក្យសម្ងាត់',
    newPassword: 'ពាក្យសម្ងាត់​ថ្មី',
    newPasswordPlaceholder: 'សូមបញ្ចូលពាក្យសម្ងាត់ថ្មី។',
    retypePassword: 'បញ្ចូលពាក្យសម្ងាត់ថ្មីម្តងទៀត',
    retypePasswordPlaceholder: 'សូមបញ្ចូលពាក្យសម្ងាត់ថ្មីម្តងទៀត',
    setupPasswordRule: 'ពាក្យសម្ងាត់ត្រូវតែមាន 6-32 តួអក្សរ ហើយមានបន្សំលេខ អក្សរ និងនិមិត្តសញ្ញាច្រើនជាងពីរ។',
    setupPasswordRuleRefer: 'ពាក្យសម្ងាត់ត្រូវមានពី 6 ទៅ 32 តួអក្សរ ហើយមានបន្សំលេខ អក្សរ និងនិមិត្តសញ្ញាច្រើនជាងពីរ។ សូមបញ្ចូលម្តងទៀត។',
    setupPasswordOk: 'ប្រាកដ',
    setupPasswordDiffer: 'ពាក្យសម្ងាត់ដែលបានបញ្ចូលពីរដងគឺមិនជាប់លាប់ទេ សូមបញ្ចូលម្តងទៀត។',
    err_def: 'បរាជ័យក្នុងការចូលប្រើ, សូមធ្វើការផ្ទុកទំព័រឡើងវិញ ហើយព្យាយាមម្តងទៀត។',
    err_2000: 'កំហុសក្នុងការបញ្ចូលលេខកូដផ្ទៀងផ្ទាត់។',
    err_2010: 'កំហុសក្នុងការបញ្ចូលព័ត៌មានចូល។',
    err_4001: 'គណនីនេះមិនត្រូវបានអនុញ្ញាតសម្រាប់មុខងារណាមួយឡើយ។',
    err_4011: 'ហាងត្រូវបានបិទប្រើហើយ មិនអាចចូលប្រើបានទេ។',
    err_4012: 'អ្នកជំនាញអាហារូបញ្ជាទេវតាចុះហើយ មិនអាចចូលប្រើបានទេ។',
    err_4013: 'ម៉ាកត្រូវបានបិទ, មិនអាចចូលប្រើបាន។',
    err_4014: 'ក្រុមត្រូវបានបិទ, មិនអាចចូលប្រើបាន។',
    err_4015: 'តំបន់ត្រូវបានបិទមិនអាចចូលបានឡើយ។',
    err_5001: 'ការកំណត់ពាក្យសម្ងាត់បរាជ័យទៀត។ សូមព្យាយាមម្តងទៀត។',
  },
}