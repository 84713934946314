export function timestampToTime(timestamp) {
  var date = new Date(timestamp * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear() - 1 + '-'
  var M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-'
  var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  var m =
    (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
  var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return Y + M + D + h + m + s
}

/**
 * 时间格式化
 * @param value
 * @param fmt
 * @returns {*}
 */
export function formatDate(value, fmt) {
  let regPos = /^\d+(\.\d+)?$/
  if (regPos.test(value)) {
    //如果是数字
    let getDate = new Date(value)
    let o = {
      'M+': getDate.getMonth() + 1,
      'd+': getDate.getDate(),
      'h+': getDate.getHours(),
      'm+': getDate.getMinutes(),
      's+': getDate.getSeconds(),
      'q+': Math.floor((getDate.getMonth() + 3) / 3),
      S: getDate.getMilliseconds(),
    }
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        (getDate.getFullYear() + '').substr(4 - RegExp.$1.length)
      )
    }
    for (let k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ('00' + o[k]).substr(('' + o[k]).length)
        )
      }
    }
    return fmt
  } else {
    //TODO
    value = value.trim()
    return value.substr(0, fmt.length)
  }
}
/**
 * 深度克隆对象、数组
 * @param obj 被克隆的对象
 * @return 克隆后的对象
 */
export function cloneObject(obj) {
  return JSON.parse(JSON.stringify(obj))
}

/**
 * 防抖
 * @param fn 要防抖的函数
 * @param delay 防抖的毫秒数
 * @returns {Function}
 */
export function simpleDebounce(fn, delay = 100) {
  let timer = null
  return function () {
    let args = arguments
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn.apply(this, args)
    }, delay)
  }
}
/**
 * 节流 500ms
 */
export function throttle(fn, delay = 500, context) {
  let inAvail = true
  return () => {
    if (inAvail) {
      fn.call(context)
      inAvail = false
      setTimeout(() => {
        inAvail = true
      }, delay)
    }
  }
}
/**
 * 第一语言第二语言切换
 * @param firstName 第一语言
 * @param secondName 第二语言
 * @return 语言
 */
export function changeLanguageName(firstName, secondName) {
  if (this.$i18n.local == 'zh') {
    return firstName
  } else {
    return secondName
  }
}

export function setCookie(key, value, days) {
  return new Promise((resolve, reject) => {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = key + '=' + (value || '') + expires;
    resolve(true);
  })
}

export function getCookie(key) {
  var arr1 = document.cookie.split('; ')
  for (var i = 0; i < arr1.length; i++) {
    var arr2 = arr1[i].split('=')
    if (arr2[0] == key) {
      return decodeURI(arr2[1])
    }
  }
}

export function removeCookie(key) {
  setCookie(key, '', -1)
}

//比较两个数组是否完全一致
export function areArraysEqual(arr1, arr2) {
  if (!arr1 || !arr2) {
    return false
  }
  if (arr1.length !== arr2.length) {
    return false
  }

  for (let i = 0; i < arr1.length; i++) {
    if (!isObjectEqual(arr1[i], arr2[i])) {
      return false
    }
  }

  return true
}
function isObjectEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (let i = 0; i < keys1.length; i++) {
    const key = keys1[i]
    if (obj1[key] !== obj2[key]) {
      return false
    }
  }

  return true
}

//转换时间戳

export function timestampToLocalTime(timestamp) {
  //  创建一个Date对象，参数为时间戳
  const date = new Date(timestamp)

  //  获取时区偏移量，单位为毫秒
  const timezoneOffset = date.getTimezoneOffset() * 60 * 1000

  //  计算本地时间戳
  const localTimestamp = date.getTime() - timezoneOffset

  //  返回本地时间戳
  return localTimestamp
}

//替换语言中的{0}
export function replaceLanguageText(value, first, second) {
  console.log(first, 'first')
  let text = value.replace('{0}', first)
  console.log(text, 'firstReplace')
  if (second) {
    text = text.replace('{1}', second)
  }
  console.log(text, 'lastReplace')
  return text
}
