export default {
  app: {
    orgCode: 'グループ/ブランド番号',
    orgCodePlaceholder: 'グループ/ブランド番号を入力してください',
    username: 'アカウント',
    usernamePlaceholder: 'ユーザー名を入力してください',
    password: 'パスワード',
    passwordPlaceholder: 'パスワードを入力してください',
    captcha: '検証コード',
    captchaPlaceholder: '確認コードを入力してください',
    captchaExpired: '認証コードをリフレッシュしてください',
    login: 'ログイン',
    remember: 'ログイン情報を記憶する',
    switchPC: 'バックステージ',
    setupPassword: 'パスワードを設定してください',
    newPassword: '新しいパスワード',
    newPasswordPlaceholder: '新しいパスワードを入力してください',
    retypePassword: '新しいパスワードをもう一度入力してください',
    retypePasswordPlaceholder: '新しいパスワードをもう一度入力してください',
    setupPasswordRule: 'パスワードは 6 ～ 32 文字で、数字、文字、記号の組み合わせを 3 つ以上含む必要があります。',
    setupPasswordRuleRefer: 'パスワードは 6 ～ 32 文字で、数字、文字、記号を 2 つ以上組み合わせてください。再入力してください。',
    setupPasswordOk: 'もちろん',
    setupPasswordDiffer: '2 回入力したパスワードは一致しません。再入力してください。',
    err_def: 'ログインに失敗しました。ページを更新して、もう一度お試しください。',
    err_2000: '間違った確認コード。',
    err_2010: 'ログイン情報が正しく入力されていません。',
    err_4001: 'このアカウントにはどの機能も許可されていません。',
    err_4011: '店舗が使用停止されており、ログインできません。',
    err_4012: 'フランチャイズパートナーが停止されており、ログインできません。',
    err_4013: 'ブランドが無効になっています。ログインできません。',
    err_4014: 'グループが無効になっています。ログインできません。',
    err_4015: 'ゾーンは無効になっており、ログインできません。',
    err_5001: 'パスワードの設定に失敗しました。もう一度お試しください。',
  },
}