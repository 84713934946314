<template>
    <van-field
        ref="field"
        autosize
        clearable
        v-model="password"
        :class="passwordVisible ? 'text-input' : 'password-input'"
        :placeholder="placeholder"
        @input="$emit('input', password)"
        @keydown.enter="$emit('enter')"
    >
        <template #left-icon>
            <img
                src="@/assets/image/password.png"
                alt=""
                width="16px"
                height="16px"
            />
        </template>
        <template slot="right-icon">
            <span class="solts" @click="passwordVisible = !passwordVisible">
                <van-icon name="eye" v-if="passwordVisible" />
                <van-icon name="closed-eye" v-else />
            </span>
        </template>
    </van-field>
</template>
  
<script>
    export default {
        name: "PasswordField",
        componentName: "PasswordField",
        model: {
            prop: "inputValue",
            event: "input",
        },
        props: {
            /**
             * 当前输入的值
             */
            inputValue: {
                type: String,
                default: "",
            },
            /**
             * 占位提示文字
             */
            placeholder: {
                type: String,
                default: "",
            },
        },
        data() {
            return {
                password: this.inputValue,
                passwordVisible: false,
            };
        },
        methods: {
            focus() {
                this.$refs.field.focus();
            },
            setValue(value) {
                this.password = value;
            },
        },
    };
</script>

<style scoped lang="less">
    .text-input /deep/.van-field__control {
        -webkit-text-security: none;
    }
    .password-input /deep/.van-field__control {
        -webkit-text-security: disc;
    }
</style>