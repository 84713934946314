export default {
  app: {
    orgCode: 'Kod Kumpulan/Jenama',
    orgCodePlaceholder: 'Sila masukkan kod kumpulan/jenama',
    username: 'Akaun',
    usernamePlaceholder: 'Sila masukkan Nama Pengguna',
    password: 'Kata laluan',
    passwordPlaceholder: 'Sila masukkan kata laluan',
    captcha: 'Kod pengesahan',
    captchaPlaceholder: 'Sila masukkan kod pengesahan',
    captchaExpired: 'Sila segarkan kod pengesahan',
    login: 'Log masuk',
    remember: 'Ingat maklumat log masuk',
    switchPC: 'Tukar ke bahagian belakan',
    setupPassword: 'Tetapkan kata laluan',
    newPassword: 'Kata laluan baharu',
    newPasswordPlaceholder: 'Sila masukkan kata laluan baharu',
    retypePassword: 'Masukkan kata laluan baharu sekali lagi',
    retypePasswordPlaceholder: 'Sila masukkan kata laluan baharu sekali lagi',
    setupPasswordRule: 'Kata laluan mestilah 6-32 aksara dan mengandungi lebih daripada dua kombinasi nombor, huruf dan simbol.',
    setupPasswordRuleRefer: 'Kata laluan mestilah 6-32 aksara dan mengandungi lebih daripada dua kombinasi nombor, huruf dan simbol. Sila masukkan semula.',
    setupPasswordOk: 'Pasti',
    setupPasswordDiffer: 'Kata laluan yang dimasukkan dua kali tidak konsisten, sila masukkan semula.',
    err_def: 'Log masuk gagal, sila segarkan halaman dan cuba lagi.',
    err_2000: 'Kod pengesahan yang salah.',
    err_2010: 'Maklumat log masuk salah dimasukkan.',
    err_4001: 'Akaun ini tidak dibenarkan untuk sebarang fungsi.',
    err_4011: 'Kedai telah dinyahaktifkan, tidak dapat log masuk.',
    err_4012: 'Rakan Francais telah dinyahaktifkan, tidak dapat log masuk.',
    err_4013: 'Jenama telah dilumpuhkan, tidak boleh log masuk.',
    err_4014: 'Kumpulan telah dilumpuhkan, tidak boleh log masuk.',
    err_4015: 'Kawasan telah dinyahaktifkan dan log masuk tidak mungkin.',
    err_5001: 'Penetapan kata laluan gagal, sila cuba lagi.',
  },
}