<template>
  <div id="app">
    <router-view safe-area-inset-bottom />
  </div>
</template>
<script>
export default {
  methods: {
    changeLange() {
      this.$i18n.locale = 'en'
    },
  },
}
</script>

<style lang="less">
#app {
  background-color: #f5f5f5;
}

.inexa {
  z-index: 10000000001 !important;
}

.van-tab {
  width: 120px !important;

  .van-tab__text {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px !important;
  }
}

.van-toast {
  .van-toast__text {
    white-space: pre-wrap;
    word-break: break-word;
  }
}
</style>
