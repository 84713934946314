import axios from 'axios'

let baseURL = process.env.VUE_APP_API_BASE_URL
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
axios.defaults.baseURL = baseURL

//返回状态判断(添加响应拦截器)
axios.interceptors.response.use(
  (res) => {
    //对响应数据做些事

    return res
  },
  (error) => {
    // console.log('网络异常')
    if (error.message.includes('timeout')) {
      // 判断请求异常信息中是否含有超时timeout字符串
      return Promise.reject(error) // reject这个错误信息
    }
    return Promise.reject(error)
  }
)

//返回一个Promise(发送post请求)
export function post(url, data = {}, header = {}) {
  let headers = header
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      axios
        .post(url, data, {
          headers,
        })
        .then(
          (response) => {
            resolve(response.data)
          },
          (err) => {
            reject(err)
          }
        )
        .catch((error) => {
          reject(error)
        })
    }, 100)
  })
}

////返回一个Promise(发送get请求)
export function get(url, params, header = {}) {
  let headers = header
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      axios
        .get(url, {
          params: params,
          headers,
        })
        .then(
          (response) => {
            resolve(response.data)
          },
          (err) => {
            reject(err)
          }
        )
        .catch((error) => {
          reject(error)
        })
    }, 100)
  })
}
