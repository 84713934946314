export default {
  app: {
    orgCode: 'Mã số Nhóm/Thương hiệu',
    orgCodePlaceholder: 'Vui lòng nhập mã số nhóm/thương hiệu',
    username: 'tài khoản',
    usernamePlaceholder: 'Vui lòng nhập tên người dùng',
    password: 'mật khẩu',
    passwordPlaceholder: 'xin vui lòng nhập mật khẩu',
    captcha: 'mã xác nhận',
    captchaPlaceholder: 'vui lòng nhập mã xác nhận',
    captchaExpired: 'Vui lòng làm mới mã xác nhận',
    login: 'Đăng nhập',
    remember: 'Ghi nhớ thông tin đăng nhập',
    switchPC: 'Chuyển sang phía backend',
    setupPassword: 'đặt mật khẩu',
    newPassword: 'mật khẩu mới',
    newPasswordPlaceholder: 'Vui lòng nhập mật khẩu mới',
    retypePassword: 'Nhập lại mật khẩu mới',
    retypePasswordPlaceholder: 'Vui lòng nhập lại mật khẩu mới',
    setupPasswordRule: 'Mật khẩu phải có 6-32 ký tự và chứa nhiều hơn hai tổ hợp số, chữ cái và ký hiệu.',
    setupPasswordRuleRefer: 'Mật khẩu cần phải có 6-32 ký tự và chứa nhiều hơn hai tổ hợp số, chữ cái và ký hiệu. Vui lòng nhập lại.',
    setupPasswordOk: 'Chắc chắn',
    setupPasswordDiffer: 'Mật khẩu nhập 2 lần không khớp, vui lòng nhập lại.',
    err_def: 'Đăng nhập thất bại, vui lòng làm mới trang và thử lại.',
    err_2000: 'Mã xác minh không chính xác.',
    err_2010: 'Thông tin đăng nhập nhập sai.',
    err_4001: 'Tài khoản này không được ủy quyền cho bất kỳ chức năng nào.',
    err_4011: 'Cửa hàng đã bị ngưng sử dụng, không thể đăng nhập.',
    err_4012: 'Đối tác nhượng quyền đã bị ngưng sử dụng, không thể đăng nhập.',
    err_4013: 'Thương hiệu đã bị vô hiệu hóa, không thể đăng nhập.',
    err_4014: 'Nhóm đã bị vô hiệu hóa, không thể đăng nhập.',
    err_4015: 'Khu vực bị vô hiệu hóa và không thể đăng nhập.',
    err_5001: 'Thiết lập mật khẩu thất bại, vui lòng thử lại.',
  },
}